/* CSS Animations */

@keyframes fade-out {
	0% {
		visibility: visible;
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0.03;
		transform: translateY(15%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0.01;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translateX(50%);
	}
	85% {
		transform: translateX(-3%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translateX(-50%);
	}
	85% {
		transform: translateX(3%);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes cardEnter {
	from {
		opacity: 0.01;
		transform: translateY(-25%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}

@keyframes rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

@keyframes slideOutRight {
	from {
		transform: translateX(0);
		visibility: visible;
	}
	to {
		transform: translateX(100%);
		visibility: hidden;
	}
}

@keyframes slideInRight {
	from {
		transform: translateX(100%);
		visibility: hidden;
	}
	to {
		transform: translateX(0);
		visibility: visible;
	}
}

@keyframes rotation {
	0% {
		--gradiant-angle: 0deg;
	}
	100% {
		--gradiant-angle: 360deg;
	}
}
