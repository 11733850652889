.app-footer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: auto;
	min-height: 70px;
	padding: 15px;
	text-align: center;
	color: var(--light-slate);
}

.app-footer a {
	padding: 10px;
}
.app-footer p {
	font-size: clamp(var(--fz-xxs), 5vw, var(--fz-xs));
}

.mobile-footer {
	display: block;
	width: 100%;
	max-width: 270px;
	margin: 0px auto 10px px;
}

.mobile-footer ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	margin: 0px;
	list-style: none;
}

.mobile-footer ul li a {
	padding: 10px;
}

.mobile-footer ul li a svg {
	width: 20px;
	height: 20px;
}
.app-footer .normal-footer p {
	width: fit-content;
}

.app-footer .normal-footer a {
	max-width: 85%;
	text-wrap: nowrap;
	text-align: center;
}

@media screen and (min-width: 768px) {
	.mobile-footer {
		display: none;
	}
}
