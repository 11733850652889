.other-projects_section {
	padding: 60px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.other-projects_section h2 {
	font-size: clamp(var(--clamped-heading));
}

.other-projects_section a.archive-link {
	font-family: var(--font-mono);
	font-size: var(--fz-xs);
	margin-top: 5px;
}

.other-projects_section ul.projects-grid {
	list-style: none;
	padding: 0px;
	margin: 50px 0px 0px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 15px;
	position: relative;
}

.other-projects_section li.other-projects-list-item {
	opacity: 0;
	transform: translateY(15%);
	position: relative;
	cursor: default;
	padding: 5px;
}

.other-projects_section li.other-projects-list-item a {
	position: relative;
	z-index: 1;
}

.other-projects_section li.other-projects-list-item.activated {
	animation: fadeInUp 0.5s forwards;
}

.other-projects_section .card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
	padding: 3rem 2.5rem;
}

.other-projects_section .card .project-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
}

.other-projects_section .card .project-top .folder {
	color: var(--green);
}
.other-projects_section .card .project-top .folder svg {
	width: 40px;
	height: 40px;
}

.other-projects_section .card .project-top .project-links {
	display: flex;
	align-items: center;
	margin-right: -10px;
	color: var(--light-slate);
}

.other-projects_section .card .project-top .project-links a {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 7px;
}

.other-projects_section .card .project-top .project-links a svg {
	width: 20px;
	height: 20px;
}
.other-projects_section .card .project-top .project-links a.external svg {
	width: 22px;
	height: 22px;
}

.other-projects_section .card h3.project-title {
	margin: 0px 0px 10px;
	color: var(--lightest-slate);
	font-size: var(--fz-xl);
	line-height: 1.3;
}

.other-projects_section .card .project-title a {
	position: static;
}

.other-projects_section .card .project-title a::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
}

.other-projects_section .card .project-description {
	color: var(--light-slate);
}
.other-projects_section .card .project-description p {
	font-size: 14px;
}

.other-projects_section .card ul.project-tech-list {
	display: flex;
	align-items: flex-end;
	flex-grow: 1;
	flex-wrap: wrap;
	padding: 0px;
	margin: 20px 0px 0px;
	list-style: none;
}

.other-projects_section .card ul.project-tech-list li {
	font-family: var(--font-mono);
	font-size: var(--fz-xxs);
	line-height: 1.75;
}

.other-projects_section .card ul.project-tech-list li:not(:last-of-type) {
	margin-right: 15px;
}

.other-projects_section .more-button {
	padding: 2rem 2.55rem;
	margin: 80px auto 0px;
}

@media (prefers-reduced-motion: no-preference) {
	.other-projects_section li.other-projects-list-item:hover .card:before,
	.other-projects_section li.other-projects-list-item:hover .card:after,
	.other-projects_section li.other-projects-list-item:focus-within .card:after,
	.other-projects_section li.other-projects-list-item:focus-within .card:after {
		animation: none;
		opacity: 0;
	}
}

/*////////////////////////////////////////////////////////// 
TODO: 480px ~ OtherProjects
////////////////////////////////////////////////////////// */
@media screen and (min-width: 480px) {
	.other-projects_section {
		padding: 80px 0px;
	}
}

/*////////////////////////////////////////////////////////// 
TODO: 600px ~ OtherProjects
////////////////////////////////////////////////////////// */
@media screen and (min-width: 600px) {
}
/*////////////////////////////////////////////////////////// 
TODO: 768px ~ OtherProjects
////////////////////////////////////////////////////////// */
@media screen and (min-width: 768px) {
	.other-projects_section li.other-projects-list-item {
		opacity: 0;
		transform: translateY(-25%);
		padding: 15px;
	}

	.other-projects_section li.other-projects-list-item.activated {
		animation: cardEnter 0.3s forwards calc(var(--delay) * 0.15s);
	}
	.other-projects_section {
		padding: 100px 0px;
		max-width: 1000px;
	}

	.other-projects_section .card {
		padding: 4rem 2.5rem;
	}
}

/*////////////////////////////////////////////////////////// 
TODO: 1024px ~ OtherProjects
////////////////////////////////////////////////////////// */
@media screen and (min-width: 1024px) {
}

/*////////////////////////////////////////////////////////// 
TODO: 1080px ~ OtherProjects
////////////////////////////////////////////////////////// */
@media screen and (min-width: 1080px) {
	.other-projects_section li.other-projects-list-item {
		padding: 20px;
	}
	.other-projects_section ul.projects-grid {
		gap: 0px;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}
}
